import {useLoaderData} from '@remix-run/react';
import type {
  ActionFunctionArgs,
  LinksFunction,
} from '@remix-run/server-runtime';

import handleWaitlistFormSubmission from '@/utils/server/action/waitlist/handleWaitlistFormSubmission.server';
import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {BlogSubNav} from '@/components/pages/blog/BlogSubNav/BlogSubNav';
import {ColorSchemeEnum, Theme} from '@/enums';
import {blogIndexLoader} from '@/utils/server/loader/blogIndexLoader.server';
import {type IndexSectionArticles} from '@/utils/server/blog/indexPageDefinitions/types.server';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import blogStylesheet from '@/stylesheets/blog/blog.css?url';
import FeaturedSectionsIndex from '@/components/pages/blog/BlogIndex/FeaturedSectionsIndex';
import SimpleSectionsIndex from '@/components/pages/blog/BlogIndex/SimpleSectionsIndex';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {useTranslations} from '@/hooks/useTranslations';
import type {Handle} from '@/types';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';
import {BlogHeroConversion} from '@/components/pages/blog/BlogHeroConversion/BlogHeroConversion';
import {EN_BLOG_PATH} from '@/components/blog/constants';

export const loader = blogIndexLoader;

export const handle: Handle = {
  theme: Theme.Blog,
};

export function action({request}: ActionFunctionArgs) {
  return handleWaitlistFormSubmission(request);
}

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

export default function Blog() {
  const {t} = useTranslations();

  const {
    featuredHeroArticle,
    heroArticles = [],
    latestArticles = [],
    mostReadArticles = [],

    category1Articles = [],
    category2Articles = [],
    miniCategory2Articles = [],
    category3Articles = [],
    featuredCategory3Articles = [],
    category4Articles = [],

    localizedSettings,
  } = useLoaderData<IndexSectionArticles>() ?? {};

  const pageTitle = useBlogPageTitle(t('htmlHead.pageTitle'));

  const hasFeaturedSections =
    miniCategory2Articles.length > 0 && featuredCategory3Articles.length > 0;

  const conversion = (
    <BlogConversion
      heading={t('sellAnywhere.headingHtml')}
      subhead={t('sellAnywhere.subheadHtml')}
      buttonHref={t('sellAnywhere.button.href')}
      buttonText={t('sellAnywhere.button.text')}
      disclaimer={t('sellAnywhere.disclaimerHtml')}
      mode="green"
    />
  );

  const heroConversionCta = featuredHeroArticle && <BlogHeroConversion />;

  const commonIndexSectionProps = {
    featuredHeroArticle,
    heroArticles,
    latestArticles,
    category1Articles,
    category2Articles,
    category3Articles,
    category4Articles,
    mostReadArticles,
    settings: localizedSettings,
    enBlogPath: EN_BLOG_PATH.CORE,
    heroConversionCta,
    blogType: BLOG_PAGE_TYPE.BLOG,
  };

  return (
    <PageLayout
      title={pageTitle}
      header={(props) => (
        <BlogSubNav
          {...props}
          enBlogPath={EN_BLOG_PATH.CORE}
          blogPageType={BLOG_PAGE_TYPE.BLOG}
        />
      )}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
      isPureTitle
    >
      <div className="blog relative bg-white pt-36 text-black">
        {hasFeaturedSections ? (
          <FeaturedSectionsIndex
            {...commonIndexSectionProps}
            blogType={BLOG_PAGE_TYPE.BLOG}
            miniCategory2Articles={miniCategory2Articles}
            featuredCategory3Articles={featuredCategory3Articles}
          />
        ) : (
          <SimpleSectionsIndex {...commonIndexSectionProps} />
        )}
        {conversion}
      </div>
    </PageLayout>
  );
}
